import { DialogProgrammatic as Dialog } from 'buefy'
export default {
	Error({message,title="LỖI"}){
		return Dialog.alert({
			title: title,
			message: message,
			type: 'is-danger',
			hasIcon: true,
			icon: 'times-circle',
			iconPack: 'fa',
			ariaRole: 'alertdialog',
			ariaModal: true
		})
	},
	Success({message,title="OK"}){
		return Dialog.alert({
			title: title,
			message: message,
			type: 'is-success',
			hasIcon: true,
			icon: 'info-circle',
			iconPack: 'fa',
			ariaRole: 'alertdialog',
			ariaModal: true
		})
	},
	Confirm({message,title="Xác nhận",onConfirm,confirmText="Xác nhận"}){
		return Dialog.confirm({
			title: title,
			message: message,
			type: 'is-warning',
			hasIcon: true,
			icon: 'info-circle',
			iconPack: 'fa',
			ariaRole: 'alertdialog',
			ariaModal: true,
			cancelText: 'Hủy',
			confirmText,
			onConfirm:()=>onConfirm()
		})
	}
}