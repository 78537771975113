import Notify from '@/helpers/notify.js'
import Toast from '@/helpers/toast.js'
export default class News {
	ListCategory(){
		const Token = this.Token
		const Options = this.category.Options({Token})
		this.isLoading = true
		return this.category.Read({Options}).then(resp => {
			const {data} = resp;
			if (data) {
				return data;
			} else {
				Notify.Error({message: 'Không thể tải danh sách'})
				return [];
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể tải danh sách'})
			return [];
		}).finally(() => {
			this.isLoading = false;
		})
	}
	GetCategory({id}){
		const Token = this.Token
		const Options = this.category.Options({Token})
		this.isLoading = true
		return this.category.Read({id,Options}).then(resp => {
			const {data} = resp;
			if (data) {
				this.Category = data;
				this.Box.Add = true;
			} else {
				Notify.Error({message: 'Không thể tải thông tin danh mục'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể tải thông tin danh mục'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	AddCategory({Name, Slug}){
		this.isLoading = true;
		const Token = this.Token
		const Options = this.category.Options({Token, Post: {Name, Slug}})
		return this.category.Create({Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã thêm mới dữ liệu !!!'})
				this.Box.Add = false;
				this.Category = {
					Name: '',
					Slug: '',
				}
				this.doFetchListCategory()
			} else {
				Notify.Error({message: 'Không thể thêm dữ liệu !!!'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể thêm dữ liệu !!!'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	EditCategory(ID, {Name, Slug}){
		const Token = this.Token
		const Options = this.category.Options({Token, Post: {Name, Slug}})
		this.category.Update({ID, Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã chỉnh sửa dữ liệu !!!'})
				this.Box.Add = false;
				this.doFetchListCategory()
			} else {
				Notify.Error({message: 'Không thể chỉnh sửa dữ liệu !!!'})
			}
		})
	}
	DeleteCategory(ID){
		this.isLoading = true;
		const Token = this.Token
		const Options = this.category.Options({Token})
		this.category.Delete({ID, Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã xóa dữ liệu !!!'})
				this.doFetchListCategory()
			} else {
				Notify.Error({message: 'Không thể xóa dữ liệu !!!'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể xóa dữ liệu !!!'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	ListTopic(){
		const Token = this.Token
		const Options = this.topic.Options({Token})
		this.isLoading = true
		return this.topic.Read({Options}).then(resp => {
			const {data} = resp;
			if (data) {
				return data;
			} else {
				Notify.Error({message: 'Không thể tải danh sách'})
				return [];
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể tải danh sách'})
			return [];
		}).finally(() => {
			this.isLoading = false;
		})
	}
	GetTopic({id}){
		const Token = this.Token
		const Options = this.topic.Options({Token})
		this.isLoading = true
		return this.topic.Read({id,Options}).then(resp => {
			const {data} = resp;
			if (data) {
				this.Topic = data;
				this.Box.Add = true;
			} else {
				Notify.Error({message: 'Không thể tải thông tin bài viết'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể tải thông tin bài viết'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	AddTopic({Title, Slug, Category, Published, Content, Description}){
		this.isLoading = true;
		const Token = this.Token
		const Options = this.topic.Options({Token, Post: {Title, Slug, Category, Published, Content, Description}})
		return this.topic.Create({Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã thêm mới dữ liệu !!!'})
				this.Box.Add = false;
				this.doFetchListTopic();
			} else {
				Notify.Error({message: 'Không thể thêm dữ liệu !!!'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể thêm dữ liệu !!!'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	EditTopic(ID, {Title, Slug, Category, Published, Content, Description}){
		this.isLoading = true;
		const Token = this.Token
		const Options = this.topic.Options({Token, Post: {Title, Slug, Category, Published, Content, Description}})
		this.topic.Update({ID, Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã chỉnh sửa dữ liệu !!!'})
				this.Box.Add = false;
				this.doFetchListTopic();
			} else {
				Notify.Error({message: 'Không thể chỉnh sửa dữ liệu !!!'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể chỉnh sửa dữ liệu !!!'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	DeleteTopic(ID){
		this.isLoading = true;
		const Token = this.Token
		const Options = this.topic.Options({Token})
		this.topic.Delete({ID, Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã xóa dữ liệu !!!'})
				this.doFetchListTopic();
			} else {
				Notify.Error({message: 'Không thể xóa dữ liệu !!!'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể xóa dữ liệu !!!'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
	PublishTopic(ID,Published){
		this.isLoading = true;
		const Token = this.Token
		const Options = this.topic.Options({Token,Post:{Published}})
		this.topic.Custom({URL:`publish/${ID}`, Options}).then(resp => {
			const {success} = resp;
			if (success) {
				Toast.Success({message: 'Đã cập nhật dữ liệu !!!'})
				this.doFetchListTopic();
			} else {
				Notify.Error({message: 'Không thể cập nhật dữ liệu !!!'})
			}
		}).catch(e=>{
			Notify.Error({message: 'Không thể cập nhật dữ liệu !!!'})
		}).finally(() => {
			this.isLoading = false;
		})
	}
}