import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Baiviet from '@/views/Baiviet.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bai-viet',
    name: 'Baiviet',
    component: Baiviet
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/AuthPage.vue')
  },
  {
    path: '/no_access',
    name: 'NoAccess',
    component: () => import('@/views/NoAccess.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/*
router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/no_access']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.getters.Token
  if (authRequired && !loggedIn) {
    //console.log(store.getters)
    return next('/login')
  }
  next()
})
*/
export default router
