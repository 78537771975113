import { ToastProgrammatic as Toast } from 'buefy'
export default {
	Error({message}){
		return Toast.open({
			message: message,
			type: 'is-danger'
		})
	},
	Success({message}){
		return Toast.open({
			message: message,
			type: 'is-success'
		})
	}
}