<template>
  <div class="container is-full-width">
    <section>
      <b-field grouped group-multiline>
        <div class="control" v-if="UserData.Permision === 'admin'">
          <b-button
              label="Tạo mới"
              class="is-primary"
              icon-left="plus"
              @click="doNew" />
        </div>
      </b-field>
      <b-table
          :loading="isLoading"
          :data="itemsWithIndex"
          striped
          mobile-cards
      >
        <b-table-column field="index" label="ID" width="auto" sticky :td-attrs="()=>null" numeric v-slot="props">
          {{ props.row.index }}
        </b-table-column>
        <b-table-column field="Name" label="Name" sticky searchable width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.Name }}
        </b-table-column>
        <b-table-column field="Topics" label="Bài viết" numeric width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.Topics }}
        </b-table-column>
        <b-table-column field="Slug" label="Slug" sticky searchable width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.Slug }}
        </b-table-column>
        <b-table-column field="id" label="Thao tác" :td-attrs="()=>null" centered v-slot="props">
          <b-field grouped group-multiline>
            <div class="control">
              <b-button
                  label="Sửa"
                  size="is-small"
                  class="is-info"
                  icon-left="pencil"
                  @click="GetCategory({id:props.row.id})" />
            </div>
            <div class="control">
              <b-button
                  label="Xóa"
                  size="is-small"
                  class="is-danger"
                  icon-left="delete"
                  @click="doDelete(props.row.id)" />
            </div>
          </b-field>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">KHÔNG CÓ DỮ LIỆU</div>
        </template>
      </b-table>
    </section>
    <b-modal
        v-model="Box.Add"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        auto-focus
        aria-role="dialog"
        aria-modal
        :can-cancel="['x']"
    >
      <template #default="props">
        <div class="modal-card" style="width: 600px">
          <header class="modal-card-head">
            <p class="modal-card-title" v-if="!Category._id">Tạo danh mục mới</p>
            <p class="modal-card-title" v-else>Cập nhật danh mục {{Category.Name}}</p>
            <button
                type="button"
                class="delete"
                @click="Box.Add = false"/>
          </header>
          <section class="modal-card-body">
            <b-field label="Tên danh mục">
              <b-input
                  type="text"
                  v-model="Category.Name"
                  placeholder="Tên danh mục"
                  required
                  @input="doSlug"
              />
            </b-field>

            <b-field label="Slug">
              <b-input
                  type="text"
                  v-model="Category.Slug"
                  placeholder="Slug"
                  required
              />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
                v-if="!Category._id"
                label="Tạo"
                type="is-primary"
                :loading="isLoading"
                :disabled="isLoading"
                @click="doAdd"
            />
            <b-button
                v-else
                label="Cập nhật"
                type="is-primary"
                :loading="isLoading"
                :disabled="isLoading"
                @click="doUpdate"
            />
          </footer>
        </div>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Notify from '@/helpers/notify.js'
import Toast from '@/helpers/toast.js'
import StaffHelper from '@/modules/staff.js'
import NewsHelper from '@/modules/news.js'
import Request from '@/helpers/fetch.js'
import Strings from '@/helpers/strings.js'
import '@/main.js'
const StaffService = new StaffHelper();
const NewsService = new NewsHelper();
export default {
  name: 'Home',
  data(){
    return {
      isLoading: true,
      Box:{
        Add: false,
      },
      Category:{
        Name: '',
        Slug: '',
      },
      items: [],
      stickyHeaders: true,
      category: new Request('manager/news/category'),
    }
  },
  computed: {
    ...mapGetters(['Token','UserData']),
    itemsWithIndex(){
      return this.items.map((item,index)=>({
        ...item,
        index:index+1,
        id:item._id,
      }))
    },
    columns() {
      return [
        {
          field: "index",
          label: "ID",
          width: "auto",
          numeric: true,
          sticky: true,
          headerClass: "is-sticky-column-one",
          cellClass: "is-sticky-column-one"
        },
        {
          field: "Name",
          label: "Tên Danh Mục",
          width: "auto",
          searchable: true,
          sticky: true,
          headerClass: "is-sticky-column-two",
          cellClass: "is-sticky-column-two"
        },
        {
          field: "Slug",
          label: "Slug",
          width: "auto",
          searchable: true,
          sticky: true,
          headerClass: "is-sticky-column-tree",
          cellClass: "is-sticky-column-tree"
        },
        {
          field: "id",
          label: "Thao tác",
          width: "auto",
        },
      ];
    },
  },
  methods:{
    doCheckAuth:StaffService.doCheckAuth,
    Auth:StaffService.Auth,
    ListCategory:NewsService.ListCategory,
    AddCategory:NewsService.AddCategory,
    DeleteCategory:NewsService.DeleteCategory,
    GetCategory:NewsService.GetCategory,
    UpdateCategory:NewsService.EditCategory,
    doDelete(ID){
      Notify.Confirm({
        message:'Bạn có muốn xóa dữ liệu ? <br>Hành động này không thể phục hồi !!!',
        title:'Xác nhận xóa ?',
        onConfirm: ()=>this.DeleteCategory(ID)
      })
    },
    doSlug(){
      const {Name} = this.Category;
      if(Name){
        this.Category.Slug = Strings.Slug(Name)
      }
    },
    doAdd(){
      const {Name, Slug} = this.Category
      if(!Name || !Slug) return Toast.Error({message:'Điền đầy đủ thông tin !!!'})
      this.AddCategory(this.Category)
    },
    doUpdate(){
      const {_id, Name, Slug} = this.Category
      if(!Name || !Slug) return Toast.Error({message:'Điền đầy đủ thông tin !!!'})
      this.UpdateCategory(_id,{Name, Slug})
    },
    doNew(){
      this.Category = {
        Name: '',
        Slug: '',
      }
      this.Box.Add = true;
    },
    doFetchListCategory(result){
      if(!result) return;
      this.ListCategory().then(resp=>{
        this.items = resp;
      })
    }
  },
  mounted(){
    /*this.doCheckAuth(true).then(()=>{
      this.ListCategory().then(resp=>{
        console.log(resp)
      })
    })*/
    this.doCheckAuth(true).then(this.doFetchListCategory)
  }
}
</script>
