import {API_ENDPOINT} from '@/config.js'
export default class StaffService {
	doCheckAuth(hide=false){
		const Token = this.$route.query.token || this.Token;
		if(!hide) this.isLoading = true
		return this.Auth(Token)
			.then(resp=>{
			const {data} = resp;
			if(data.Name && data.Permision){
				if(data.Permision === 'admin' || data.Permision === 'manager' || data.Permision === 'marketing') {
					this.$store.commit('doLogin', Token);
					this.$store.commit('doSetUserData', data);
					if(!hide) {
						this.$buefy.toast.open({
							message: 'Đã đăng nhập thành công. Vui lòng đợi !!!',
							type: 'is-success'
						})
						setTimeout(() => {
							this.$router.push('/')
						}, 500)
					}
				}else{
					this.$buefy.toast.open({
						message: 'Tài khoản của bạn không được quyền truy cập vào hệ thống !!!',
						type: 'is-warning'
					})
					setTimeout(() => {
						this.$router.push('/no_access')
					}, 100)
				}
				return true;
			}else{
				this.$store.commit('doLogout');
				this.$buefy.toast.open({
					message: 'Không kiểm tra được thông tin đăng nhập. Thử đăng nhập lại !!!',
					type: 'is-warning'
				})
			}
			return false;
		})
			.catch(e=>{
			this.$store.commit('doLogout');
			if(e === 'No_Token')
			{
				this.$buefy.toast.open({
					message: 'Thông tin Token không hợp lệ. Thử đăng nhập lại !!!',
					type: 'is-warning'
				})
			}else {
				this.$buefy.toast.open({
					message: 'Không kiểm tra được thông tin đăng nhập. Thử đăng nhập lại !!!',
					type: 'is-warning'
				})
			}
			return false;
		})
			.finally(() => {
				this.isLoading = false
			})
	}
	Auth(token){
		const Token = token || this.Token;
		if (!Token) return Promise.reject('No_Token');
		return fetch(`${API_ENDPOINT}/staff/auth`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': Token,
			},
		}).then(resp => {
			return resp.json();
		});
	}
}