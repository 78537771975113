<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <h1>HBC-CMS v0.1.3</h1>
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        Danh mục
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/bai-viet' }">
        Bài viết
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "navbar"
}
</script>

<style scoped>

</style>