<template>
  <div class="container is-fluid">
    <section>
      <b-field grouped group-multiline>
        <div class="control">
          <b-button
              label="Tạo mới"
              class="is-primary"
              icon-left="plus"
              @click="doNew" />
        </div>
      </b-field>
      <b-table
          :loading="isLoading"
          :data="itemsWithIndex"
          striped
          mobile-cards
      >
        <b-table-column field="index" label="ID" width="auto" sticky :td-attrs="()=>null" numeric v-slot="props">
          {{ props.row.index }}
        </b-table-column>
        <b-table-column field="Title" label="Tiêu đề" sticky searchable width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.Title }}
        </b-table-column>
        <b-table-column field="PostedBy.Username" label="Đăng bởi" sticky searchable width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.PostedBy.Username }}
        </b-table-column>
        <b-table-column field="Category.Name" label="Danh mục" searchable width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.Category.Name }}
        </b-table-column>
        <b-table-column field="Slug" label="Slug" searchable width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ props.row.Slug }}
        </b-table-column>
        <b-table-column field="updatedAt" label="Cập nhật" width="auto" :td-attrs="()=>null" centered v-slot="props">
          {{ new Date(props.row.updatedAt).toLocaleDateString() }}
        </b-table-column>
        <b-table-column field="Published" label="Trạng thái" width="auto" :td-attrs="()=>null" centered v-slot="props">
          <b-switch
              :value="props.row.Published"
              disabled
          >
            {{props.row.Published ? 'Public':'Nháp'}}
          </b-switch>
        </b-table-column>
        <b-table-column field="id" label="Thao tác" :td-attrs="()=>null" centered v-slot="props">
          <b-field grouped group-multiline>
            <div class="control">
              <b-button
                  label="Sửa"
                  size="is-small"
                  class="is-info"
                  icon-left="pencil"
                  @click="GetTopic({id:props.row.id})"
              />
            </div>
            <div class="control">
              <b-button
                  label="Xóa"
                  size="is-small"
                  class="is-danger"
                  icon-left="delete"
                  @click="doDelete(props.row.id)" />
            </div>
            <div class="control">
              <b-button
                  :label="props.row.Published ? 'Nháp':'Public'"
                  size="is-small"
                  class="is-primary"
                  icon-left="earth"
                  @click="PublishTopic(props.row.id,!props.row.Published)" />
            </div>
          </b-field>
        </b-table-column>
        <template #empty>
          <div class="has-text-centered">KHÔNG CÓ DỮ LIỆU</div>
        </template>
      </b-table>
    </section>
    <b-modal
        v-model="Box.Add"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        auto-focus
        aria-role="dialog"
        aria-modal
        :can-cancel="['x']"
        full-screen
    >
      <template #default="props">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title" v-if="!Topic._id">Viết bài mới</p>
            <p class="modal-card-title" v-else>Chỉnh sửa bài viết: {{Topic.Title}}</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Tiêu đề bài viết" horizontal>
              <b-input
                  type="text"
                  v-model="Topic.Title"
                  placeholder="Tiêu đề bài viết"
                  required
                  @input="doSlug"
              />
            </b-field>

            <b-field label="Slug" horizontal>
              <b-input
                  type="text"
                  v-model="Topic.Slug"
                  placeholder="Slug"
                  required
              />
            </b-field>

            <b-field label="Giới thiệu" horizontal>
              <b-input
                  type="textarea"
                  v-model="Topic.Description"
                  placeholder="Nội dung giới thiệu"
                  required
              />
            </b-field>
            <b-field label="Danh mục" horizontal>
              <b-select placeholder="Lựa chọn" v-model="Topic.Category">
                <option
                    v-for="cat in categories"
                    :value="cat._id"
                    :key="cat._id">
                  {{ cat.Name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Chế độ đăng" horizontal>
              <b-switch
                  v-model="Topic.Published"
              >
                {{Topic.Published ? 'Public':'Nháp'}}
              </b-switch>
            </b-field>
            <b-field label="Nội dung" horizontal>
              <quill-editor v-model="Topic.Content" :options="{theme: 'snow'}" class="editor" />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
                v-if="!Topic._id"
                label="Đăng"
                type="is-primary"
                :loading="isLoading"
                :disabled="isLoading"
                @click="doAdd"
            />
            <b-button
                v-else
                label="Cập nhật"
                type="is-primary"
                :loading="isLoading"
                :disabled="isLoading"
                @click="doUpdate"
            />
          </footer>
        </div>
      </template>
    </b-modal>
    <b-loading :is-full-page="true" v-model="isLoading" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Notify from '@/helpers/notify.js'
import Toast from '@/helpers/toast.js'
import StaffHelper from '@/modules/staff.js'
import NewsHelper from '@/modules/news.js'
import Request from '@/helpers/fetch.js'
import Strings from '@/helpers/strings.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import '@/main.js'
const StaffService = new StaffHelper();
const NewsService = new NewsHelper();
export default {
  name: "Baiviet",
  components: {
    quillEditor,
  },
  data(){
    return {
      isLoading: true,
      Box:{
        Add: false,
      },
      Topic:{
        Title: '',
        Slug: '',
        Category: '',
        Description: '',
        Content: '',
      },
      items: [],
      categories: [],
      stickyHeaders: true,
      category: new Request('manager/news/category'),
      topic: new Request('manager/news/topic'),
    }
  },
  computed: {
    ...mapGetters(['Token']),
    itemsWithIndex(){
      return this.items.map((item,index)=>({
        ...item,
        index:index+1,
        id:item._id,
      })).filter(v=>!!v)
    },
  },
  methods:{
    doCheckAuth:StaffService.doCheckAuth,
    Auth:StaffService.Auth,
    ListCategory:NewsService.ListCategory,
    ListTopic:NewsService.ListTopic,
    AddTopic:NewsService.AddTopic,
    EditTopic:NewsService.EditTopic,
    DeleteTopic:NewsService.DeleteTopic,
    GetTopic:NewsService.GetTopic,
    PublishTopic:NewsService.PublishTopic,
    doDelete(ID){
      Notify.Confirm({
        message:'Bạn có muốn xóa dữ liệu ? <br>Hành động này không thể phục hồi !!!',
        title:'Xác nhận xóa ?',
        onConfirm: ()=>this.DeleteTopic(ID)
      })
    },
    doSlug(){
      const {Title} = this.Topic;
      if(Title){
        this.Topic.Slug = Strings.Slug(Title)
      }
    },
    doAdd(){
      const {Title, Slug, Category, Content, Description} = this.Topic
      if(!Title || !Slug || !Category || !Content || !Description) return Toast.Error({message:'Điền đầy đủ thông tin !!!'})
      this.AddTopic(this.Topic)
    },
    doUpdate(){
      const {Title, Slug, Category, Content, Description} = this.Topic
      if(!Title || !Slug || !Category || !Content || !Description) return Toast.Error({message:'Điền đầy đủ thông tin !!!'})
      this.EditTopic(this.Topic._id,this.Topic)
    },
    doFetchListCategory(){
      return this.ListCategory().then(resp=>{
        this.categories = resp
      });
    },
    doFetchListTopic(){
      return this.ListTopic().then(resp=>{
        this.items = resp
        this.Topic = {
          ...this.Topic,
          Title: '',
          Slug: '',
          Description: '',
          Content: '',
        }
        this.$forceUpdate();
      });
    },
    doNew(){
      this.Topic = {
        ...this.Topic,
        Title: '',
        Slug: '',
        Description: '',
        Content: '',
      }
      this.Box.Add = true;
    }
  },
  mounted(){
    this.doCheckAuth(true).then(this.doFetchListTopic).then(this.doFetchListCategory);
  }
}
</script>

<style scoped>
.editor{
  height: 100%;
}
</style>