import {API_ENDPOINT} from '@/config.js'
export default class Request {
	constructor(API) {
		this.API = `${API_ENDPOINT}/${API}`
	}
	Options = ({Token,Post}) => {
		Token = Token ? {'Authorization': Token}:{}
		const Body  = Post ? JSON.stringify(Post):undefined
		const Method = Post ? 'POST':'GET'
		const headers =  Object.assign({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},Token)
		return {
			method: Method,
			headers: headers,
			body: Body
		}
	}
	Create = ({Options}) =>{
		return fetch(`${this.API}/add`,Options).then(resp=>{
			return resp.json()
		})
	}
	Read = ({id,Options}) =>{
		const API = (id) ? `${this.API}/${id}`:`${this.API}`
		return fetch(API,Options).then(resp=>{
			return resp.json()
		})
	}
	Update = ({ID,Options}) =>{
		return fetch(`${this.API}/update/${ID}`,Options).then(resp=>{
			return resp.json()
		})
	}
	Delete = ({ID,Options}) =>{
		return fetch(`${this.API}/delete/${ID}`,Options).then(resp=>{
			return resp.json()
		})
	}
	Custom = ({URL,Options}) =>{
		return fetch(`${this.API}/${URL}`,Options).then(resp=>{
			return resp.json()
		})
	}
}